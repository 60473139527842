<template>
  <div class="country-phone-number">
    <v-menu bottom offset-y v-model="menuVisible" nudge-top="-10px" rounded="xl">
      <template #activator="{ on, attrs }">
        <v-text-field
          filled
          rounded
          :placeholder="$t('lang.home.enter.phoneNumber')"
          hide-details
          background-color="#F7F8F9"
          v-model="phoneNumber"
          class="body-2 pl-0 pr-0"
          type="tel"
          solo
          flat
          @change="() => $emit('input', phoneNumber)"
        >
          <template #prepend>
            <div class="country-icon" v-on="on" v-bind="attrs">
              <span
                v-if="countryArr[selectedItem]"
                :class="`ico ico-${countryArr[selectedItem].countryCode}`"
              ></span>
              <v-icon v-if="!menuVisible">mdi-menu-down</v-icon>
              <v-icon v-else>mdi-menu-up</v-icon>
            </div>
          </template>
        </v-text-field>
      </template>
      <v-list :max-height="xs ? 200 : 300">
        <v-list-item-group v-model="selectedItem" light @change="selectedItemChange" class="group">
          <v-list-item v-for="(item, index) in countryArr" :key="item.countryCode">
            <div class="options">
              <div class="left">
                <span :class="`ico ico-${item.countryCode} mr-2`"></span>
                <span class="text-subtitle-2 t">{{ $t(item.name) }}</span>
                <span class="text-subtitle-2 subTitle">+{{ item.smsCode }}</span>
              </div>
              <v-icon dark :color="themeColor" v-if="selectedItem === index">mdi-check-bold</v-icon>
            </div>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import { themeColor } from "@/common/const";
import { mapState } from "vuex";
export default {
  props: {
    value: [Number, String],
  },
  data() {
    return {
      themeColor,
      menuVisible: false,
      selectedItem: 0,
      phoneNumber: this.value || "",
    };
  },
  computed: {
    ...mapState(["countryArr"]),
    xs() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  watch: {
    countryArr: {
      handler(nv) {
        if (!nv.length) return;
        const index = this.countryArr.findIndex((i) => i.current && i.current === 1);
        if (!~index) return;
        this.selectedItem = index;
        this.$emit("country", this.countryArr[this.selectedItem]);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getData() {
      const { smsCode, id } = this.countryArr[this.selectedItem];
      return {
        countryId: id,
        smsCode,
        phoneNumber: this.phoneNumber,
      };
    },
    changeSelectedItem(index) {
      this.selectedItem = index;
    },
    selectedItemChange() {
      this.$emit("country", this.countryArr[this.selectedItem]);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/sass/sprite.scss";
.options {
  display: flex;
  align-content: center;
  cursor: pointer;
  width: 100%;
  justify-content: space-around;
  line-height: 30px !important;
  .left {
    flex: 1;
    display: flex;
    align-content: center;
  }
  .t {
    line-height: 30px !important;
  }
  .subTitle {
    color: #999;
    line-height: 30px !important;
    margin: 0 10px;
  }
}

.country-icon {
  padding: 0 10px;
  border-right: 1px solid #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.country-phone-number {
  background: #f7f8f9;
  border-radius: 14px;
}
.group {
  background-color: #fff;
}
</style>
